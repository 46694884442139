import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="An image of multiple icons placed in a grid" src="https://user-images.githubusercontent.com/980622/210349372-9392f4b3-4902-4fd3-ab6f-fbd3587f208a.png" />
    <h2>{`Library`}</h2>
    <p>{`At Primer, we utilize the `}<a parentName="p" {...{
        "href": "https://primer.style/octicons"
      }}>{`Octicons`}</a>{` icon library. This package is available in `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/packages/ruby"
      }}>{`Ruby`}</a>{`, `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/packages/jekyll"
      }}>{`Jekyll`}</a>{`, `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/packages/javascript"
      }}>{`JavaScript`}</a>{`, `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/packages/react"
      }}>{`React`}</a>{` and `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/packages/styled-system"
      }}>{`Styled System`}</a>{`.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Icons are most appropriate for representing simple, recognizable concepts or actions. When choosing icons for your design system, consider the following guidelines:`}</p>
    <ol>
      <li parentName="ol">{`Where possible use icons to supplement text, rather than replacing it.`}</li>
      <li parentName="ol">{`Make sure that the meaning of icons is clear when used without accompanying text.`}</li>
      <li parentName="ol">{`Choose icons that are easy to recognize and accurately depict the concept or action.`}</li>
      <li parentName="ol">{`Maintain consistency in the use of icons across your product or brand.`}</li>
      <li parentName="ol">{`Avoid using icons for complex or abstract concepts that may be confusing to some users.`}</li>
      <li parentName="ol">{`Adhere to predetermined icon sizes to ensure legibility and ease of recognition.`}</li>
      <li parentName="ol">{`Test the effectiveness of your icons with users to ensure that they are understood and useful in navigating the interface.`}</li>
    </ol>
    <h2>{`Color`}</h2>
    <p>{`To ensure that icons are effective and legible in different color modes, it is recommended to use our `}<a parentName="p" {...{
        "href": "/foundations/color#foregrounds"
      }}>{`functional foreground colors`}</a>{`.`}</p>
    <p>{`Many of our components are designed to automatically set the color of icons to a predetermined color. For example, banners are configured to set the icon to the matching `}<a parentName="p" {...{
        "href": "/foundations/color#foregrounds"
      }}>{`role color`}</a>{` by default.`}</p>
    <img width="960" alt="A banner showing a warning icon in orange with the text 'Your password was reset recently.'" src="https://user-images.githubusercontent.com/980622/210349549-e7444c43-bb52-4a84-b00e-13962a22377a.png" />
    <p>{`Some icons should always be shown in a specific colors when used within a specific context. This helps to effectively convey their intended meaning and provide a consistent user experience. Here are a few examples:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8zm6.5-.25A.75.75 0 017.25 7h1a.75.75 0 01.75.75v2.75h.25a.75.75 0 010 1.5h-2a.75.75 0 010-1.5h.25v-2h-.25a.75.75 0 01-.75-.75zM8 6a1 1 0 100-2 1 1 0 000 2z" fill="#0366D6"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/info-16"
            }}>{`info`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.accent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Important information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z" fill="#22863A"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/check-16"
            }}>{`check`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.success`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Successful, passing, or positive result`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M3.72 3.72a.75.75 0 011.06 0L8 6.94l3.22-3.22a.75.75 0 111.06 1.06L9.06 8l3.22 3.22a.75.75 0 11-1.06 1.06L8 9.06l-3.22 3.22a.75.75 0 01-1.06-1.06L6.94 8 3.72 4.78a.75.75 0 010-1.06z" fill="#D73A49"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/x-16"
            }}>{`x`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.danger`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Error, danger, or negative result`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8.22 1.754a.25.25 0 00-.44 0L1.698 13.132a.25.25 0 00.22.368h12.164a.25.25 0 00.22-.368L8.22 1.754zm-1.763-.707c.659-1.234 2.427-1.234 3.086 0l6.082 11.378A1.75 1.75 0 0114.082 15H1.918a1.75 1.75 0 01-1.543-2.575L6.457 1.047zM9 11a1 1 0 11-2 0 1 1 0 012 0zm-.25-5.25a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-2.5z" fill="#B08800"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/alert-16"
            }}>{`alert`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.attention`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Warning`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Specific use cases`}</h2>
    <p>{`Certain `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/"
      }}>{`Octicons`}</a>{` are designed for specific use cases and their meaning should not be changed. Most icons also have a predefined color variable, those should not be changed unless placed on colorful/dark backgrounds like buttons. In this case the `}<a parentName="p" {...{
        "href": "/foundations/color#foregrounds"
      }}>{`fg.onEmphasis`}</a>{` color can be used. Please refer to the table below for icons with specific guidelines.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Context`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#57606A"><path d="M11.93 8.5a4.002 4.002 0 01-7.86 0H.75a.75.75 0 010-1.5h3.32a4.002 4.002 0 017.86 0h3.32a.75.75 0 010 1.5zm-1.43-.75a2.5 2.5 0 10-5 0 2.5 2.5 0 005 0z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/git-commit-16"
            }}>{`git-commit`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Commit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.muted`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Individual or groups of commits.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#1A7F37"><path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path><path d="M8 0a8 8 0 110 16A8 8 0 018 0zM1.5 8a6.5 6.5 0 1013 0 6.5 6.5 0 00-13 0z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/issue-opened-16"
            }}>{`issue-opened`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.success`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A newly opened issue that needs attention.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#8250DF"><path d="M11.28 6.78a.75.75 0 00-1.06-1.06L7.25 8.69 5.78 7.22a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l3.5-3.5z"></path><path d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-1.5 0a6.5 6.5 0 10-13 0 6.5 6.5 0 0013 0z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/issue-closed-16"
            }}>{`issue-closed`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.done`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A done/closed issue.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#1A7F37"><path d="M5.029 2.217a6.5 6.5 0 019.437 5.11.75.75 0 101.492-.154 8 8 0 00-14.315-4.03L.427 1.927A.25.25 0 000 2.104V5.75A.25.25 0 00.25 6h3.646a.25.25 0 00.177-.427L2.715 4.215a6.491 6.491 0 012.314-1.998zM1.262 8.169a.75.75 0 00-1.22.658 8.001 8.001 0 0014.315 4.03l1.216 1.216a.25.25 0 00.427-.177V10.25a.25.25 0 00-.25-.25h-3.646a.25.25 0 00-.177.427l1.358 1.358a6.501 6.501 0 01-11.751-3.11.75.75 0 00-.272-.506z"></path><path d="M9.06 9.06a1.5 1.5 0 11-2.12-2.12 1.5 1.5 0 012.12 2.12z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/issue-reopened-16"
            }}>{`issue-reopened`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.success`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A reopened issue.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#57606A"><path d="M14.307 11.655a.75.75 0 01.165 1.048 8.05 8.05 0 01-1.769 1.77.75.75 0 01-.883-1.214 6.552 6.552 0 001.44-1.439.75.75 0 011.047-.165zm-2.652-9.962a.75.75 0 011.048-.165 8.05 8.05 0 011.77 1.769.75.75 0 01-1.214.883 6.552 6.552 0 00-1.439-1.44.75.75 0 01-.165-1.047zM6.749.097a8.074 8.074 0 012.502 0 .75.75 0 11-.233 1.482 6.558 6.558 0 00-2.036 0A.751.751 0 016.749.097zM.955 6.125a.75.75 0 01.624.857 6.558 6.558 0 000 2.036.75.75 0 11-1.482.233 8.074 8.074 0 010-2.502.75.75 0 01.858-.624zm14.09 0a.75.75 0 01.858.624c.13.829.13 1.673 0 2.502a.75.75 0 11-1.482-.233 6.558 6.558 0 000-2.036.75.75 0 01.624-.857zm-8.92 8.92a.75.75 0 01.857-.624 6.558 6.558 0 002.036 0 .75.75 0 11.233 1.482c-.829.13-1.673.13-2.502 0a.75.75 0 01-.624-.858zm-4.432-3.39a.75.75 0 011.048.165 6.552 6.552 0 001.439 1.44.751.751 0 01-.883 1.212 8.05 8.05 0 01-1.77-1.769.75.75 0 01.166-1.048zm2.652-9.962A.75.75 0 014.18 2.74a6.556 6.556 0 00-1.44 1.44.751.751 0 01-1.212-.883 8.05 8.05 0 011.769-1.77.75.75 0 011.048.166z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/issue-draft-16"
            }}>{`issue-draft`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.muted`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A draft issue.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#1A7F37"><path d="M1.5 3.25a2.25 2.25 0 113 2.122v5.256a2.251 2.251 0 11-1.5 0V5.372A2.25 2.25 0 011.5 3.25zm5.677-.177L9.573.677A.25.25 0 0110 .854V2.5h1A2.5 2.5 0 0113.5 5v5.628a2.251 2.251 0 11-1.5 0V5a1 1 0 00-1-1h-1v1.646a.25.25 0 01-.427.177L7.177 3.427a.25.25 0 010-.354zM3.75 2.5a.75.75 0 100 1.5.75.75 0 000-1.5zm0 9.5a.75.75 0 100 1.5.75.75 0 000-1.5zm8.25.75a.75.75 0 101.5 0 .75.75 0 00-1.5 0z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/git-pull-request-16"
            }}>{`git-pull-request`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pull request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.success`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A new unmerged pull request that needs attention.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#CF222E"><path d="M3.25 1A2.25 2.25 0 014 5.372v5.256a2.251 2.251 0 11-1.5 0V5.372A2.251 2.251 0 013.25 1zm9.5 5.5a.75.75 0 01.75.75v3.378a2.251 2.251 0 11-1.5 0V7.25a.75.75 0 01.75-.75zm-2.03-5.273a.75.75 0 011.06 0l.97.97.97-.97a.748.748 0 011.265.332.75.75 0 01-.205.729l-.97.97.97.97a.751.751 0 01-.018 1.042.751.751 0 01-1.042.018l-.97-.97-.97.97a.749.749 0 01-1.275-.326.749.749 0 01.215-.734l.97-.97-.97-.97a.75.75 0 010-1.06zM2.5 3.25a.75.75 0 101.5 0 .75.75 0 00-1.5 0zM3.25 12a.75.75 0 100 1.5.75.75 0 000-1.5zm9.5 0a.75.75 0 100 1.5.75.75 0 000-1.5z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/git-pull-request-closed-16"
            }}>{`git-pull-request-closed`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pull request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.danger`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A closed pull request that wasn't merged.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#57606A"><path d="M3.25 1A2.25 2.25 0 014 5.372v5.256a2.251 2.251 0 11-1.5 0V5.372A2.251 2.251 0 013.25 1zm9.5 14a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5zM2.5 3.25a.75.75 0 101.5 0 .75.75 0 00-1.5 0zM3.25 12a.75.75 0 100 1.5.75.75 0 000-1.5zm9.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zM14 7.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm0-4.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/git-pull-request-draft-16"
            }}>{`git-pull-request-draft`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pull request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.muted`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A draft pull request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#8250DF"><path d="M5.45 5.154A4.25 4.25 0 009.25 7.5h1.378a2.251 2.251 0 110 1.5H9.25A5.734 5.734 0 015 7.123v3.505a2.25 2.25 0 11-1.5 0V5.372a2.25 2.25 0 111.95-.218zM4.25 13.5a.75.75 0 100-1.5.75.75 0 000 1.5zm8.5-4.5a.75.75 0 100-1.5.75.75 0 000 1.5zM5 3.25a.75.75 0 100 .005V3.25z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/git-merge-16"
            }}>{`git-merge`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pull request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.done`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A merged request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#57606A"><path d="M2 2.5A2.5 2.5 0 014.5 0h8.75a.75.75 0 01.75.75v12.5a.75.75 0 01-.75.75h-2.5a.75.75 0 010-1.5h1.75v-2h-8a1 1 0 00-.714 1.7.75.75 0 11-1.072 1.05A2.495 2.495 0 012 11.5zm10.5-1h-8a1 1 0 00-1 1v6.708A2.486 2.486 0 014.5 9h8zM5 12.25a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v3.25a.25.25 0 01-.4.2l-1.45-1.087a.249.249 0 00-.3 0L5.4 15.7a.25.25 0 01-.4-.2z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/repo-16"
            }}>{`repo`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Repository`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.muted`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A public repository.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#9A6700"><path d="M1 2.5A2.5 2.5 0 013.5 0h8.75a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0V1.5h-8a1 1 0 00-1 1v6.708A2.492 2.492 0 013.5 9h2.75a.75.75 0 010 1.5H3.5a1 1 0 100 2h2.75a.75.75 0 010 1.5H3.5A2.5 2.5 0 011 11.5v-9z"></path><path d="M9 10.168V9a3 3 0 116 0v1.168c.591.281 1 .884 1 1.582v2.5A1.75 1.75 0 0114.25 16h-4.5A1.75 1.75 0 018 14.25v-2.5c0-.698.409-1.3 1-1.582zM13.5 10V9a1.5 1.5 0 00-3 0v1z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/repo-locked-16"
            }}>{`repo-locked`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Repository`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.attention`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A private repository. Not to be confused with `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/lock-16"
            }}>{`lock`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#24292F"><path d="M8 0c4.42 0 8 3.58 8 8a8.013 8.013 0 01-5.45 7.59c-.4.08-.55-.17-.55-.38 0-.27.01-1.13.01-2.2 0-.75-.25-1.23-.54-1.48 1.78-.2 3.65-.88 3.65-3.95 0-.88-.31-1.59-.82-2.15.08-.2.36-1.02-.08-2.12 0 0-.67-.22-2.2.82-.64-.18-1.32-.27-2-.27-.68 0-1.36.09-2 .27-1.53-1.03-2.2-.82-2.2-.82-.44 1.1-.16 1.92-.08 2.12-.51.56-.82 1.28-.82 2.15 0 3.06 1.86 3.75 3.64 3.95-.23.2-.44.55-.51 1.07-.46.21-1.61.55-2.33-.66-.15-.24-.6-.83-1.23-.82-.67.01-.27.38.01.53.34.19.73.9.82 1.13.16.45.68 1.31 2.69.94 0 .67.01 1.3.01 1.49 0 .21-.15.45-.55.38A7.995 7.995 0 010 8c0-4.42 3.58-8 8-8z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/mark-github-16"
            }}>{`mark-github`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Branding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.default`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See our `}<a parentName="td" {...{
              "href": "https://brand.github.com/"
            }}>{`GitHub brand guidelines`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 16" width="45" height="16" fill="#24292F"><path d="M8.81 7.35v5.74c0 .04-.01.11-.06.13 0 0-1.25.89-3.31.89-2.49 0-5.44-.78-5.44-5.92S2.58 1.99 5.1 2c2.18 0 3.06.49 3.2.58.04.05.06.09.06.14L7.94 4.5c0 .09-.09.2-.2.17-.36-.11-.9-.33-2.17-.33-1.47 0-3.05.42-3.05 3.73s1.5 3.7 2.58 3.7c.92 0 1.25-.11 1.25-.11v-2.3H4.88c-.11 0-.19-.08-.19-.17V7.35c0-.09.08-.17.19-.17h3.74c.11 0 .19.08.19.17zm35.85 2.33c0 3.43-1.11 4.41-3.05 4.41-1.64 0-2.52-.83-2.52-.83s-.04.46-.09.52c-.03.06-.08.08-.14.08h-1.48c-.1 0-.19-.08-.19-.17l.02-11.11c0-.09.08-.17.17-.17h2.13c.09 0 .17.08.17.17v3.77s.82-.53 2.02-.53l-.01-.02c1.2 0 2.97.45 2.97 3.88zM27.68 2.43c.09 0 .17.08.17.17v11.11c0 .09-.08.17-.17.17h-2.13c-.09 0-.17-.08-.17-.17l.02-4.75h-3.31v4.75c0 .09-.08.17-.17.17h-2.13c-.08 0-.17-.08-.17-.17V2.6c0-.09.08-.17.17-.17h2.13c.09 0 .17.08.17.17v4.09h3.31V2.6c0-.09.08-.17.17-.17zm8.26 3.64c.11 0 .19.08.19.17l-.02 7.47c0 .09-.06.17-.17.17H34.6c-.07 0-.14-.04-.16-.09-.03-.06-.08-.45-.08-.45s-1.13.77-2.52.77c-1.69 0-2.92-.55-2.92-2.75V6.25c0-.09.08-.17.17-.17h2.14c.09 0 .17.08.17.17V11c0 .75.22 1.09.97 1.09s1.3-.39 1.3-.39V6.26c0-.11.06-.19.17-.19zm-17.406 5.971h.005a.177.177 0 01.141.179v1.5c0 .07-.03.14-.09.16-.1.05-.74.22-1.27.22-1.16 0-2.86-.25-2.86-2.69V8.13h-1.11c-.09 0-.17-.08-.17-.19V6.58c0-.08.05-.15.13-.17.07-.01 1.16-.28 1.16-.28V3.96c0-.08.05-.13.14-.13h2.16c.09 0 .14.05.14.13v2.11h1.59c.08 0 .16.08.16.17v1.7c0 .11-.07.19-.16.19h-1.59v3.131c0 .47.27.83 1.05.83.247 0 .481-.049.574-.05zM12.24 6.06c.09 0 .17.08.17.17v7.37c0 .18-.05.27-.25.27h-1.92c-.17 0-.3-.07-.3-.27V6.26c0-.11.08-.2.17-.2zm29.99 3.78c0-1.81-.73-2.05-1.5-1.97-.6.04-1.08.34-1.08.34v3.52s.49.34 1.22.36c1.03.03 1.36-.34 1.36-2.25zM11.19 2.68c.75 0 1.36.61 1.36 1.38 0 .77-.61 1.38-1.36 1.38-.77 0-1.38-.61-1.38-1.38 0-.77.61-1.38 1.38-1.38zm7.34 9.35v.001l.01.01h-.001l-.005-.001v.001c-.009-.001-.015-.011-.024-.011z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/logo-github-16"
            }}>{`logo-github`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Branding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/foundations/color#foregrounds"
            }}>{`fg.default`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See our `}<a parentName="td" {...{
              "href": "https://brand.github.com/"
            }}>{`GitHub brand guidelines`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Sizes`}</h2>
    <img width="960" alt="The x-circle icon shown in three different sizes." src="https://user-images.githubusercontent.com/980622/210349362-fdcef7ea-7c22-4473-a1fd-d435037b2a86.png" />
    <p>{`Octicons are available in three sizes: `}<inlineCode parentName="p">{`12px`}</inlineCode>{`, `}<inlineCode parentName="p">{`16px`}</inlineCode>{` and `}<inlineCode parentName="p">{`24px`}</inlineCode>{`. To maintain consistency in stroke width and legibility, it is important to use these icons only at their designated sizes.`}</p>
    <p>{`The smaller set of `}<inlineCode parentName="p">{`12px`}</inlineCode>{` icons is available for use in specific, condensed user interface contexts. These icons are listed below and should only be used in the appropriate context.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M4.855.708c.5-.896 1.79-.896 2.29 0l4.675 8.351a1.312 1.312 0 01-1.146 1.954H1.33A1.313 1.313 0 01.183 9.058zM7 7V3H5v4zm-1 3a1 1 0 100-2 1 1 0 000 2z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/alert-fill-12"
            }}>{`alert-fill`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For cautionary messaging or to inform the user that an action requires attention`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M6 0a6 6 0 110 12A6 6 0 016 0zm-.705 8.737L9.63 4.403 8.392 3.166 5.295 6.263l-1.7-1.702L2.356 5.8l2.938 2.938z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/check-circle-fill-12"
            }}>{`check-circle-fill`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For positive messaging to inform the user that an action is successful, complete, or that they may continue through a workflow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M6 0a6 6 0 110 12A6 6 0 016 0zm3 5H3v2h6z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/no-entry-fill-12"
            }}>{`no-entry-fill`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicate an ending or that the user is blocked and cannot continue`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M1.757 10.243a6.001 6.001 0 118.488-8.486 6.001 6.001 0 01-8.488 8.486zM6 4.763l-2-2L2.763 4l2 2-2 2L4 9.237l2-2 2 2L9.237 8l-2-2 2-2L8 2.763z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/x-circle-fill-12"
            }}>{`x-circle-fill`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For negative messaging to inform the user that an error has occurred as a result of an action or an action is unavailable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M6 8.8c-.2 0-.4-.1-.5-.2L2.2 5.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0L6 6.9l2.7-2.7c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1L6.6 8.5c-.2.2-.4.3-.6.3z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/chevron-down-12"
            }}>{`chevron-down`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates that a collapsible section is currently open`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/chevron-right-12"
            }}>{`chevron-right`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates that a collapsible section is currently closed`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Maintaining the original size of icons is important for visual consistency, legibility, accessibility, and simplifying the design process. Resizing icons should be avoided.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/980622/209154753-14d8d1c9-0e9c-46e4-a392-43021a65a612.png" role="presentation" width="456" alt="A pencil icon shown in their original 16px and 24px size." />
    <Caption mdxType="Caption">Keep icons in their original size.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/980622/209154759-7b1ef599-e4bd-4a75-869f-71de72329ddc.png" role="presentation" width="456" alt="A pencil icon scaled up from 16px to 24px demonstrating how the stroke increased while upscaling. Another pencil icon is scaled down from 24px to 16px and demonstrates how the stroke became too thin." />
    <Caption mdxType="Caption">Don't resize icons.</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`System vs user-selectable`}</h2>
    <p>{`There are two types of icons: `}<inlineCode parentName="p">{`system`}</inlineCode>{` and `}<inlineCode parentName="p">{`user-selectable`}</inlineCode>{` icons.`}</p>
    <p><inlineCode parentName="p">{`System`}</inlineCode>{` are used to represent core actions and concepts within the user interface. These icons are typically used consistently throughout the product and are an important part of our visual language. Examples of `}<inlineCode parentName="p">{`system`}</inlineCode>{` icons might include a `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/trash-16"
      }}>{`trash`}</a>{` icon for deleting items, or a `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/plus-circle-16"
      }}>{`plus-circle`}</a>{` icon for adding new items.`}</p>
    <p><inlineCode parentName="p">{`User-selectable`}</inlineCode>{` icons, on the other hand, are used to allow users to customize their experience or make a personal connection with the product. `}<inlineCode parentName="p">{`User-selectable`}</inlineCode>{` icons are typically less central to the functionality of the user interface and may be changed by the user as desired.`}</p>
    <p>{`At GitHub we currently use emojis as `}<inlineCode parentName="p">{`user-selectable`}</inlineCode>{` icons as they are widely recognized and understood, making them an effective way to convey emotions or personality. One benefit of using emojis as `}<inlineCode parentName="p">{`user-selectable`}</inlineCode>{` icons is that they are visually distinct from the `}<inlineCode parentName="p">{`system`}</inlineCode>{` icons used in the design system. This helps users to easily understand that the emojis are optional and can be selected by the user, rather than being a part of the core functionality of the user interface.`}</p>
    <p>{`Using `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/"
      }}>{`Octicons`}</a>{` as `}<inlineCode parentName="p">{`user-selectable`}</inlineCode>{` icons is strongly discouraged as that could create a confusing experience when used in the wrong context. For example, a user could pick a `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/issue-closed-16"
      }}>{`issue-closed`}</a>{` icon and use the label `}<inlineCode parentName="p">{`In progress`}</inlineCode>{`.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/980622/209167301-278f8dff-70a1-4bc3-9ad9-335fb365bd67.png" role="presentation" width="456" alt="A navigation side bar with multiple items including 'To do', 'Learning' and ''Ready to go. Each item has a emoji assigned and is displayed on the left of the text." />
    <Caption mdxType="Caption">Use emojis as user-selectable icons.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/980622/209167308-0c8cca85-5d78-4c60-b69b-13f5afbf0254.png" role="presentation" width="456" alt="A navigation side bar with multiple items including 'To do', 'Learning' and ''Ready to go. Each item has an octicon assigned that is displayed on the left of the text." />
    <Caption mdxType="Caption">Don't use Octicons for user-selectable icons.</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`States`}</h2>
    <p>{`To display the state of an action, use a stroke and fill icon combination or its opposite version if available. For "off" or "empty" states, use the outline icon.`}</p>
    <h3>{`Predefined pairs`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Icons`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M4.25 2.5c-1.336 0-2.75 1.164-2.75 3 0 2.15 1.58 4.144 3.365 5.682A20.565 20.565 0 008 13.393a20.561 20.561 0 003.135-2.211C12.92 9.644 14.5 7.65 14.5 5.5c0-1.836-1.414-3-2.75-3-1.373 0-2.609.986-3.029 2.456a.75.75 0 01-1.442 0C6.859 3.486 5.623 2.5 4.25 2.5zM8 14.25l-.345.666-.002-.001-.006-.003-.018-.01a7.643 7.643 0 01-.31-.17 22.075 22.075 0 01-3.434-2.414C2.045 10.731 0 8.35 0 5.5 0 2.836 2.086 1 4.25 1 5.797 1 7.153 1.802 8 3.02 8.847 1.802 10.203 1 11.75 1 13.914 1 16 2.836 16 5.5c0 2.85-2.045 5.231-3.885 6.818a22.08 22.08 0 01-3.744 2.584l-.018.01-.006.003h-.002L8 14.25zm0 0l.345.666a.752.752 0 01-.69 0L8 14.25z"></path></svg>{` / `}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M7.655 14.916L8 14.25l.345.666a.752.752 0 01-.69 0zm0 0L8 14.25l.345.666.002-.001.006-.003.018-.01a7.643 7.643 0 00.31-.17 22.08 22.08 0 003.433-2.414C13.956 10.731 16 8.35 16 5.5 16 2.836 13.914 1 11.75 1 10.203 1 8.847 1.802 8 3.02 7.153 1.802 5.797 1 4.25 1 2.086 1 0 2.836 0 5.5c0 2.85 2.045 5.231 3.885 6.818a22.075 22.075 0 003.744 2.584l.018.01.006.003h.002z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/heart-16"
            }}>{`heart`}</a>{` / `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/heart-fill-16"
            }}>{`heart-fill`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sponsor / Sponsoring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25zm0 2.445L6.615 5.5a.75.75 0 01-.564.41l-3.097.45 2.24 2.184a.75.75 0 01.216.664l-.528 3.084 2.769-1.456a.75.75 0 01.698 0l2.77 1.456-.53-3.084a.75.75 0 01.216-.664l2.24-2.183-3.096-.45a.75.75 0 01-.564-.41L8 2.694v.001z"></path></svg>{` / `}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/star-16"
            }}>{`star`}</a>{` / `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/star-fill-16"
            }}>{`star-fill`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Star / Unstar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8 16A8 8 0 108 0a8 8 0 000 16zm3.78-9.72a.75.75 0 00-1.06-1.06L6.75 9.19 5.28 7.72a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l4.5-4.5z"></path></svg>{` / `}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M2.343 13.657A8 8 0 1113.657 2.343 8 8 0 012.343 13.657zM6.03 4.97a.75.75 0 00-1.06 1.06L6.94 8 4.97 9.97a.75.75 0 101.06 1.06L8 9.06l1.97 1.97a.75.75 0 101.06-1.06L9.06 8l1.97-1.97a.75.75 0 10-1.06-1.06L8 6.94 6.03 4.97z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/check-circle-16"
            }}>{`check-circle`}</a>{` / `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/x-circle-fill-16"
            }}>{`x-circle-fill`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pass / Fail`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M.513 1.513A1.75 1.75 0 011.75 1h3.5c.55 0 1.07.26 1.4.7l.9 1.2a.25.25 0 00.2.1H13a1 1 0 011 1v.5H2.75a.75.75 0 000 1.5h11.978a1 1 0 01.994 1.117L15 13.25A1.75 1.75 0 0113.25 15H1.75A1.75 1.75 0 010 13.25V2.75c0-.464.184-.91.513-1.237z"></path></svg>{` / `}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M1.75 1A1.75 1.75 0 000 2.75v10.5C0 14.216.784 15 1.75 15h12.5A1.75 1.75 0 0016 13.25v-8.5A1.75 1.75 0 0014.25 3H7.5a.25.25 0 01-.2-.1l-.9-1.2C6.07 1.26 5.55 1 5 1H1.75z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/file-directory-open-fill-16"
            }}>{`file-directory-open-fill`}</a>{` / `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/file-directory-fill-16"
            }}>{`file-directory-fill`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Directory open / Directory closed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M8 16a2 2 0 001.985-1.75c.017-.137-.097-.25-.235-.25h-3.5c-.138 0-.252.113-.235.25A2 2 0 008 16z"></path><path fillRule="evenodd" d="M8 1.5A3.5 3.5 0 004.5 5v2.947c0 .346-.102.683-.294.97l-1.703 2.556a.018.018 0 00-.003.01l.001.006c0 .002.002.004.004.006a.017.017 0 00.006.004l.007.001h10.964l.007-.001a.016.016 0 00.006-.004.016.016 0 00.004-.006l.001-.007a.017.017 0 00-.003-.01l-1.703-2.554a1.75 1.75 0 01-.294-.97V5A3.5 3.5 0 008 1.5zM3 5a5 5 0 0110 0v2.947c0 .05.015.098.042.139l1.703 2.555A1.518 1.518 0 0113.482 13H2.518a1.518 1.518 0 01-1.263-2.36l1.703-2.554A.25.25 0 003 7.947V5z"></path></svg>{` / `}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8 1.5c-.997 0-1.895.416-2.534 1.086A.75.75 0 014.38 1.55 5 5 0 0113 5v2.373a.75.75 0 01-1.5 0V5A3.5 3.5 0 008 1.5zM4.182 4.31L1.19 2.143a.75.75 0 10-.88 1.214L3 5.305v2.642a.25.25 0 01-.042.139L1.255 10.64A1.518 1.518 0 002.518 13h11.108l1.184.857a.75.75 0 10.88-1.214l-1.375-.996a1.196 1.196 0 00-.013-.01L4.198 4.321a.733.733 0 00-.016-.011zm7.373 7.19L4.5 6.391v1.556c0 .346-.102.683-.294.97l-1.703 2.556a.018.018 0 00-.003.01.015.015 0 00.005.012.017.017 0 00.006.004l.007.001h9.037zM8 16a2 2 0 001.985-1.75c.017-.137-.097-.25-.235-.25h-3.5c-.138 0-.252.113-.235.25A2 2 0 008 16z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/bell-16"
            }}>{`bell`}</a>{` / `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/bell-slash-16"
            }}>{`bell-slash`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Subscribe / Unsubscribe`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M4.75 2.5a.25.25 0 00-.25.25v9.91l3.023-2.489a.75.75 0 01.954 0l3.023 2.49V2.75a.25.25 0 00-.25-.25h-6.5zM3 2.75C3 1.784 3.784 1 4.75 1h6.5c.966 0 1.75.784 1.75 1.75v11.5a.75.75 0 01-1.227.579L8 11.722l-3.773 3.107A.75.75 0 013 14.25V2.75z"></path></svg>{` / `}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M1.19 1.143a.75.75 0 10-.88 1.214L3 4.305v9.945a.75.75 0 001.206.596L8 11.944l3.794 2.902A.75.75 0 0013 14.25v-2.703l1.81 1.31a.75.75 0 10.88-1.214l-2.994-2.168a1.09 1.09 0 00-.014-.01L4.196 3.32a.712.712 0 00-.014-.01L1.19 1.143zM4.5 5.39v7.341l3.044-2.328a.75.75 0 01.912 0l3.044 2.328V10.46l-7-5.07zM5.865 1a.75.75 0 000 1.5h5.385a.25.25 0 01.25.25v3.624a.75.75 0 001.5 0V2.75A1.75 1.75 0 0011.25 1H5.865z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/bookmark-16"
            }}>{`bookmark`}</a>{` / `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/bookmark-slash-16"
            }}>{`bookmark-slash`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Save / Unsave`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M1.679 7.932c.412-.621 1.242-1.75 2.366-2.717C5.175 4.242 6.527 3.5 8 3.5c1.473 0 2.824.742 3.955 1.715 1.124.967 1.954 2.096 2.366 2.717a.119.119 0 010 .136c-.412.621-1.242 1.75-2.366 2.717C10.825 11.758 9.473 12.5 8 12.5c-1.473 0-2.824-.742-3.955-1.715C2.92 9.818 2.09 8.69 1.679 8.068a.119.119 0 010-.136zM8 2c-1.981 0-3.67.992-4.933 2.078C1.797 5.169.88 6.423.43 7.1a1.619 1.619 0 000 1.798c.45.678 1.367 1.932 2.637 3.024C4.329 13.008 6.019 14 8 14c1.981 0 3.67-.992 4.933-2.078 1.27-1.091 2.187-2.345 2.637-3.023a1.619 1.619 0 000-1.798c-.45-.678-1.367-1.932-2.637-3.023C11.671 2.992 9.981 2 8 2zm0 8a2 2 0 100-4 2 2 0 000 4z"></path></svg>{` / `}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M.143 2.31a.75.75 0 011.047-.167l14.5 10.5a.75.75 0 11-.88 1.214l-2.248-1.628C11.346 13.19 9.792 14 8 14c-1.981 0-3.67-.992-4.933-2.078C1.797 10.832.88 9.577.43 8.9a1.618 1.618 0 010-1.797c.353-.533.995-1.42 1.868-2.305L.31 3.357A.75.75 0 01.143 2.31zm3.386 3.378a14.21 14.21 0 00-1.85 2.244.12.12 0 00-.022.068c0 .021.006.045.022.068.412.621 1.242 1.75 2.366 2.717C5.175 11.758 6.527 12.5 8 12.5c1.195 0 2.31-.488 3.29-1.191L9.063 9.695A2 2 0 016.058 7.52l-2.53-1.832zM8 3.5c-.516 0-1.017.09-1.499.251a.75.75 0 11-.473-1.423A6.23 6.23 0 018 2c1.981 0 3.67.992 4.933 2.078 1.27 1.091 2.187 2.345 2.637 3.023a1.619 1.619 0 010 1.798c-.11.166-.248.365-.41.587a.75.75 0 11-1.21-.887c.148-.201.272-.382.371-.53a.119.119 0 000-.137c-.412-.621-1.242-1.75-2.366-2.717C10.825 4.242 9.473 3.5 8 3.5z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/eye-16"
            }}>{`eye`}</a>{` / `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/eye-closed-16"
            }}>{`eye-closed`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Watch / Unwatch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M2 7.25A5.225 5.225 0 017.25 2a5.222 5.222 0 014.767 3.029A4.472 4.472 0 0116 9.5c0 2.505-1.995 4.5-4.5 4.5h-8A3.474 3.474 0 010 10.5c0-1.41.809-2.614 2.001-3.17zm1.54.482a.75.75 0 01-.556.832c-.86.22-1.484.987-1.484 1.936 0 1.124.876 2 2 2h8c1.676 0 3-1.324 3-3s-1.324-3-3-3a.75.75 0 01-.709-.504A3.72 3.72 0 007.25 3.5C5.16 3.5 3.5 5.16 3.5 7.25c.002.146.014.292.035.436l.004.036.001.008z"></path></svg>{` / `}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M7.25 2c-.69 0-1.351.13-1.957.371a.75.75 0 10.554 1.394c.43-.17.903-.265 1.403-.265a3.72 3.72 0 013.541 2.496.75.75 0 00.709.504c1.676 0 3 1.324 3 3a3 3 0 01-.681 1.92.75.75 0 001.156.955A4.496 4.496 0 0016 9.5a4.472 4.472 0 00-3.983-4.471A5.222 5.222 0 007.25 2zM.72 1.72a.75.75 0 011.06 0l2.311 2.31c.03.025.056.052.08.08l8.531 8.532.035.034 2.043 2.044a.749.749 0 01-.326 1.275.749.749 0 01-.734-.215l-1.8-1.799a4.54 4.54 0 01-.42.019h-8A3.474 3.474 0 010 10.5c0-1.41.809-2.614 2.001-3.17a5.218 5.218 0 01.646-2.622L.72 2.78a.75.75 0 010-1.06zM3.5 7.25c.004.161.018.322.041.481a.75.75 0 01-.557.833c-.86.22-1.484.986-1.484 1.936 0 1.124.876 2 2 2h6.94L3.771 5.832A3.788 3.788 0 003.5 7.25z"></path></svg></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://primer.style/octicons/cloud-16"
            }}>{`cloud`}</a>{` / `}<a parentName="td" {...{
              "href": "https://primer.style/octicons/cloud-offline-16"
            }}>{`cloud-offline`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cloud online / Cloud offline`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Maintaining consistency by adhering to the predefined pairs is important to avoid inconsistencies, as demonstrated in the following examples`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/980622/210356419-d37ecec3-97b4-45f3-bdf9-b6dd9d4b856f.png" role="presentation" width="456" alt="A completed 'upload artifacts' block with a check-circle icon and a failed 'upload artifacts' with a x-circle-fill icon." />
    <Caption mdxType="Caption">Stick to predefined icon pairs.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/980622/210356422-b3fde0b8-c20d-4c8c-907d-a945646823d6.png" role="presentation" width="456" alt="A completed 'upload artifacts' block with a check-circle icon and a failed 'upload artifacts' with a x icon." />
    <Caption mdxType="Caption">Create your own icon pairs.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/980622/210348769-210b0bfd-7371-4748-9d30-d45700b879a1.png" role="presentation" width="456" alt="Two examples of correct off states for buttons. A 'Save' button with a outline bookmark icon and 'Unsave' button with a bookmark-slash icon. A 'Subscribe' button with a bell icon and 'Unsubscribe' button with a bell-slash icon." />
    <Caption mdxType="Caption">For "off" or "empty" states, use the slash icon.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/980622/210348770-8f77bd7f-6e00-4dc6-8a0c-db791c8ae0e8.png" role="presentation" width="456" alt="Two examples of wrong off states for buttons. A 'Save' button with a bookmark icon and 'Unsave' button with a bookmark-filled icon. A 'Subscribe' button with a outline bell icon and 'Unsubscribe' button with a filled bell-filled icon." />
    <Caption mdxType="Caption">Don't use filled icons for representing "off" or "empty states".</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Creating new icons`}</h2>
    <p>{`If you are unable to find the icon you need in our `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/"
      }}>{`Octicons`}</a>{` library, you may want to create a new icon. Follow our `}<a parentName="p" {...{
        "href": "https://primer.style/octicons/guidelines/design"
      }}>{`Octicons design guidelines`}</a>{` for guidance on creating a new icon that fits with the visual language of our design system. Keep in mind that new icons should be added only when they are necessary and meet the guidelines for use.`}</p>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`If the icon is purely decorative and does not convey any meaning (for instance, if adjacent text already provides all the necessary information/context), it is hidden from screen readers.`}</p>
    <p>{`If the icon does convey meaning, the component exposes a `}<inlineCode parentName="p">{`role="img"`}</inlineCode>{` so that its purpose will be described by assistive technologies, and the component's `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` contains the accessible name / text alternative for the icon.`}</p>
    <p>{`The accessible name or decision to make it decorative should be included in the design annotation.`}</p>
    <p>{`Unless the icon is purely decorative, it must have a contrast ratio of at least 3:1 against the background color.`}</p>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`If the author does not include an `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`, the component is assumed to be decorative, and will automatically be given an `}<inlineCode parentName="p">{`aria-hidden="true"`}</inlineCode>{` attribute.`}</p>
    <p>{`If the author includes an `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`, the component exposes a `}<inlineCode parentName="p">{`role="img"`}</inlineCode>{`, and the `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` attribute is passed to the rendered component.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <ul>
      <li parentName="ul">{`If the icon is decorative, omit the `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` attribute.`}</li>
      <li parentName="ul">{`If the icon is used to convey information or meaning, make sure to add an appropriate `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` that provides the text equivalent for the icon.`}</li>
    </ul>
    <p>{`Authors can choose the color used for the icon, so it is important to ensure that it has a `}<a parentName="p" {...{
        "href": "https://primer.style/foundations/color/accessibility"
      }}>{`contrast ratio`}</a>{` of at least 3:1 against the background color. It is recommended to use our `}<a parentName="p" {...{
        "href": "https://primer.style/foundations/color#foregrounds"
      }}>{`functional foreground colors`}</a>{`.`}</p>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">{`The icon has a contrast ratio of at least 3:1 against the background color.`}</li>
    </ul>
    <h3>{`Component tests`}</h3>
    <ul>
      <li parentName="ul">{`If the icon has been given an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` attribute, the `}<inlineCode parentName="li">{`<svg>`}</inlineCode>{` has a `}<inlineCode parentName="li">{`role="img"`}</inlineCode>{` and the `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` is exposed`}</li>
      <li parentName="ul">{`If no `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` attribute has been given, the icon is presentational and the `}<inlineCode parentName="li">{`<svg>`}</inlineCode>{` is hidden from screen readers using `}<inlineCode parentName="li">{`aria-hidden="true"`}</inlineCode>{`.`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Icon" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      